import React from "react"
import Page from "../components/page"
import pageStyles from "../components/page.module.css"
import styles from "./cookies.module.css"

export default function TermsAndConditions({ location }) {
  return (
    <Page title={"Terms and Conditions – PhotoTailors"} location={location}>
      <div className={pageStyles.sectionWrapper}>
        <div className={pageStyles.section}>
          <div className={styles.main}>
            <div>

              <p>Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the
                https://www.phototailors.com website (the “Service”) operated by Photo Tailors Limited (“us”, “we”, or
                “our”).</p>
              <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these
                Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
              <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part
                of the terms then you may not access the Service.</p>
              <p>&nbsp;</p>
              <h3>Purchases</h3>
              <p>You are encouraged to familiarise yourself with your rights contained within the Sale of Goods Act
                1979, Unfair Contract Terms Act 1977 and the Unfair Terms in Consumer Contracts Regulations 1999.</p>
              <p>If you wish to purchase any product or service made available through the Service (“Purchase”), you may
                be asked to supply certain information relevant to your Purchase including, without limitation, your
                credit card number, the expiration date of your credit card, your billing address, and your shipping
                information.</p>
              <p>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment
                method(s) in connection with any Purchase; and that (ii) the information you supply to us is true,
                correct and complete. You expressly agree that Photo Tailors Limited is not responsible for any loss or
                damage arising from the submission of false or inaccurate information.</p>
              <p>By submitting such information, you grant us the right to provide the information to third parties for
                purposes of facilitating the completion of Purchases.</p>
              <p>We reserve the right to refuse or cancel your order at any time for certain reasons including but not
                limited to: product or service availability, errors in the description or price of the product or
                service, error in your order or other reasons. You expressly agree that Photo Tailors Limited cannot
                accept any liability for loss or damage arising out of such cancellation.</p>
              <p>We reserve the right to refuse or cancel your order if fraud or an unauthorised or illegal transaction
                is suspected.</p>
              <p>&nbsp;</p>
              <h3>Availability, Errors and Inaccuracies</h3>
              <p>We are constantly updating our offerings of products and services on the Service. The products or
                services available on our Service may be mispriced, described inaccurately, or unavailable, and we may
                experience delays in updating information on the Service and in our advertising on other web sites. You
                expressly agree that any such offer of a product or service does not constitute a legal offer capable of
                attracting legal consequences.</p>
              <p>We cannot and do not guarantee the accuracy or completeness of any information, including prices,
                product images, specifications, availability, and services. We reserve the right to change or update
                information and to correct errors, inaccuracies, or omissions at any time without prior notice. Section
                “Availability, Errors and Inaccuracies” is without prejudice to existing statutory rights.</p>
              <p>&nbsp;</p>
              <h3>Contests, Sweepstakes and Promotions</h3>
              <p>Any contests, sweepstakes or other promotions (collectively, “Promotions”) made available through the
                Service may be governed by rules that are separate from these Terms. If you participate in any
                Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a
                Promotion conflict with these Terms of Service, the Promotion rules will apply. The terms and conditions
                of any other “Promotions” are independent of this agreement.</p>
              <p>&nbsp;</p>
              <h3>Accounts</h3>
              <p>When you create an account with us, you must provide us information that is accurate, complete, and
                current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate
                termination of your account on our Service.</p>
              <p>You are responsible for safeguarding the password that you use to access the Service and for any
                activities or actions under your password, whether your password is with our Service or a third-party
                service.</p>
              <p>You agree not to disclose your password to any third party. You must notify us immediately upon
                becoming aware of any breach of security or unauthorized use of your account.</p>
              <p>&nbsp;</p>
              <h3>Intellectual Property</h3>
              <p>The Service and its original content, features and functionality are and will remain the exclusive
                property of Photo Tailors Limited and its licensors. The Service is protected by copyright, trademark,
                and other laws of both the United Kingdom and foreign countries. Our trademarks and trade dress may not
                be used in connection with any product or service without the prior written consent of Photo Tailors
                Limited.</p>
              <p>&nbsp;</p>
              <h3>Links To Other Web Sites</h3>
              <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by
                Photo Tailors Limited.</p>
              <p>Photo Tailors Limited has no control over, and assumes no responsibility for, the content, privacy
                policies, or practices of any third party web sites or services. You further acknowledge and agree that
                Photo Tailors Limited shall not be responsible or liable, directly or indirectly, for any damage or loss
                caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or
                services available on or through any such web sites or services.</p>
              <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web
                sites or services that you visit.</p>
              <p>&nbsp;</p>
              <h3>Termination</h3>
              <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason
                whatsoever, including without limitation if you breach the Terms.</p>
              <p>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your
                account, you may simply discontinue using the Service.</p>
              <p>All provisions of the Terms which by their nature should survive termination shall survive termination,
                including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of
                liability.</p>
              <p>&nbsp;</p>
              <h3>Indemnification</h3>
              <p>You agree to defend, indemnify and hold harmless Photo Tailors Limited and its licensee and licensors,
                and their employees, contractors, agents, officers and directors, from and against any and all claims,
                damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to
                attorney’s fees), resulting from or arising out of a) your use and access of the Service, by you or any
                person using your account and password, or b) a breach of these Terms.</p>
              <p>&nbsp;</p>
              <h3>Limitation Of Liability</h3>
              <p>In no event shall Photo Tailors Limited, nor its directors, employees, partners, agents, suppliers, or
                affiliates, be liable for any indirect, incidental, special, consequential or punitive damages,
                including without limitation, loss of profits, data, use, goodwill, or other intangible losses,
                resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct
                or content of any third party on the Service; (iii) any content obtained from the Service; and (iv)
                unauthorized access, use or alteration of your transmissions or content, whether based on warranty,
                contract, tort (including negligence) or any other legal theory, whether or not we have been informed of
                the possibility of such damage, and even if a remedy set forth herein is found to have failed of its
                essential purpose.</p>
              <p>&nbsp;</p>
              <h3>Disclaimer</h3>
              <p>Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE”
                basis. The Service is provided without warranties of any kind, whether express or implied, including,
                but not limited to, implied warranties of merchantability, fitness for a particular purpose,
                non-infringement or course of performance.</p>
              <p>Photo Tailors Limited its subsidiaries, affiliates, and its licensors do not warrant that a) the
                Service will function uninterrupted, secure or available at any particular time or location; b) any
                errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or
                d) the results of using the Service will meet your requirements.</p>
              <p>&nbsp;</p>
              <h3>Exclusions</h3>
              <p>Without limiting the generality of the foregoing and notwithstanding any other provision of these
                terms, under no circumstances will Photo Tailors Limited ever be liable to you or any other person for
                any indirect, incidental, consequential, special, punitive or exemplary loss or damage arising from,
                connected with, or relating to your use of the Service, these Terms, the subject matter of these Terms,
                the termination of these Terms or otherwise, including but not limited to personal injury, loss of data,
                business, markets, savings, income, profits, use, production, reputation or goodwill, anticipated or
                otherwise, or economic loss, under any theory of liability (whether in contract, tort, strict liability
                or any other theory or law or equity), regardless of any negligence or other fault or wrongdoing
                (including without limitation gross negligence and fundamental breach) by Photo Tailors Limited or any
                person for whom Photo Tailors Limited is responsible, and even if Photo Tailors Limited has been advised
                of the possibility of such loss or damage being incurred.</p>
              <p>&nbsp;</p>
              <h3>Governing Law</h3>
              <p>These Terms shall be governed and construed in accordance with the laws of England and Wales, without
                regard to its conflict of law provisions.</p>
              <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
                rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining
                provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us
                regarding our Service, and supersede and replace any prior agreements we might have between us regarding
                the Service.</p>
              <p>&nbsp;</p>
              <h3>Changes</h3>
              <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
                revision is material we will try to provide at least 30 days notice prior to any new terms taking
                effect. What constitutes a material change will be determined at our sole discretion.</p>
              <p>By continuing to access or use our Service after those revisions become effective, you agree to be
                bound by the revised terms. If you do not agree to the new terms, you must stop using the service.</p>
              <p>&nbsp;</p>
              <h3>Privacy Policy and Cookie Policy</h3>
              <p>Please refer to our Privacy Policy and Cookies Policy. You agree that they constitute part of these
                terms. You must read our Privacy Policy and Cookies Policy before you use the Service.</p>
              <p>&nbsp;</p>
              <h3>Contact Us</h3>
              <p>If you have any questions about this Privacy Policy, please&nbsp;<a
                href="/contact-us/">contact us.</a></p>
            </div>
          </div>
        </div>
      </div>
    </Page>


  )
}
